import { api } from '@/main'

const profile = {
  namespaced: true,  
  state: () => ({
    loaded: false,
    data: null,
    programs: null
  }),
  mutations: {
    startLoad(state) {
      state.data = null
      state.programs = null
      state.loaded = false
    },
    set(state, newData) {
      if (!!newData) {
        state.data = newData.profile
        state.programs = newData.programs
      } else {
        state.data = null
        state.programs = null
      }
      state.loaded = true
    }
  },
  actions: {
    async reload({ commit, state }) {
      //commit('startLoad')
      let profile = await api.queries.selectRow('GJO_GET_USER_INFO',{})
      if (profile === null) throw new Error('Вход невозможен. Профиль не найден.')
      let programs = await api.queries.select('GJO_GET_ACTIVE_PROGRAM_LIST',{},1000,0)
      commit('set', {profile, programs})
    }
  },
  getters: {
    fullName(state) {
      const d = state.data
      if (!d) return ''
      return d.LAST_NAME+" "+d.FIRST_NAME+" "+d.PATRONYMIC
    }    
  }
}

export default profile