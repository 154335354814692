import dayjs from "dayjs"
import customParseFormat from 'dayjs/plugin/customParseFormat.js'
import utc from 'dayjs/plugin/utc.js'
import timezone from 'dayjs/plugin/timezone.js'
import relativeTime from 'dayjs/plugin/relativeTime.js'
import isToday from 'dayjs/plugin/isToday'
import dayjsRu from 'dayjs/locale/ru'
dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(isToday)
dayjs.locale(dayjsRu)

export default dayjs