export async function loadConfig() {
    try {
        let response = await fetch('/config.json')
        let newConfig = await response.json()

        //domain override
        let host = window.location.host;
        console.log('Current host:',host);
        if ((newConfig.domains) && (newConfig.domains[host])) {
          for (let name in newConfig.domains[host]) {
            newConfig[name] = newConfig.domains[host][name];
          }
        }
        return newConfig
    } catch(e) {
        let msg = 'Failed to load config.json: '+e.message
        alert(msg)
        throw e
    }    
}