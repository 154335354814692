<template>
  <div class="cabinet-alert uk-card uk-box-shadow-small uk-card-small go-border uk-border-rounded uk-margin-small-top">
    <div class="body">
      <h4>Уважаемые покупатели!</h4>
      <p>Просим Вас уведомлять СПб ГБУ «Горжилобмен» в случае изменения Ваших личных данных (смена паспорта) и контактных номеров телефонов.</p>
    </div>
  </div>
</template>
<style lang="sass">
  .cabinet-alert
    padding: 10px  20px
    background-color: #fffac7
    h4
      margin: 0 0 5px 0
    p
      margin: 0
</style>