import { api } from '@/main'

const session = {
  namespaced: true,  
  state: () => ({
    values: null,
  }),
  mutations: {
    load(state) {
      let rawSession = localStorage.getItem('session')
      if (!rawSession) return
      let sess = JSON.parse(rawSession)
      if (!sess.token) return
      state.values = sess
      api.setToken(sess.token, sess.project)
    },
    set(state, newSession) {
      state.values = newSession
      api.setToken(newSession.token, newSession.project)
      localStorage.setItem('session',JSON.stringify(newSession))
    },
    clear(state) {
      state.values = null
      api.setToken(null)
      localStorage.removeItem('session')
    }
  },
  actions: {
    async auth({ commit, state }, params) {
      if (!!state.values) {
        api.logout().then().catch(e => {})
        commit('clear')
      }
      let project = params.project
      await api.login(params.login, params.password, params.project)
      //console.log("!!!",{ token: api.token, project })
      commit('set',{ token: api.token, project })
    },
    async logout({ commit, state }) {
      if (!!state.values) {
        api.logout().then().catch(e => {})
        commit('clear')
      }
    }
  }
}

export default session