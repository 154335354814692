<template>
  <div class="uk-offcanvas-content">
    <div class="go-container flex-column">
      <Header />
      <!--content-->
      <div class="go-content uk-flex-1" style="padding: 5px">
          <Cabinet v-if="authorised" />
          <Alert v-if="authorised" />
          <slot></slot>
      </div>
      <Footer />
    </div>
    <MobileMenu />
  </div>
</template>
<script setup>
  import Header from './header.vue'
  import Footer from './footer.vue'
  import MobileMenu from './mobile-menu.vue'
  import Cabinet from './cabinet.vue'
  import Alert from './alert.vue'
  import { computed } from 'vue'
  import { useStore } from 'vuex'

  const store = useStore()
  
  const authorised = computed(() => {
    return !!store.state.profile.data
  })
</script>
<style lang="sass">
  @import "../assets/fonts/PTSans/stylesheet.css"
  //@import "air-datepicker/air-datepicker.css"
  @import "vanillajs-datepicker/sass/datepicker.scss"
  @import "../assets/flex.sass"
  @import "../assets/uikit.sass"
  @import "../assets/styles/styles.sass"
  @import "../assets/styles2/styles2.sass"
  @import "../assets/choices/choices.sass"
</style>