<template>
    <header class="go-header">
      <!--header-top-->
      <div class="go-header-top">
        <div class="flex-row" style="align-items: center;">
          <div class="">
            <a href="//obmencity.ru"><img class="go-header-top--logo" src="@/assets/logo.png" width="103" height="111"></a>
          </div>
          <div class="flex-grow" style="padding-left: 15px; overflow: hidden;">
            <div class="go-header-logo-title">
              <div class="go-header-logo-title--main uk-visible@l">Правительство Санкт-Петербурга</div>
              <div class="go-header-logo-title--main uk-visible@l">Жилищный комитет</div>
              <div class="go-header-logo-title--main">Санкт-Петербургское государственное бюджетное учреждение</div>
              <div class="go-header-logo-title--heading">Горжилобмен</div>
              <div class="go-header-logo-title--meta uk-visible@l">Основано в 1927 году</div>
            </div>
          </div>
          <div class="uk-visible@m uk-text-nowrap">
            <ul class="go-header-contacts">
              <li>Справочная служба: <a href="tel:+78125760000">+7 (812) 576 00 00</a></li>
              <li>Россия, Санкт-Петербург, ул. Бронницкая, д. 32</li>
              <li><a href="mailto:info@obmencity.ru">info@obmencity.ru</a></li>
            </ul>
          </div>
        </div>
      </div>
      <!--header-nav-->
      <div class="gjo-header-nav" uk-sticky="top: 100; animation: uk-animation-slide-top; show-on-up: true">
        <nav class="uk-navbar-container" uk-navbar>
          <!--Menu-->
          <div class="nav-overlay uk-navbar-left">
            <!--side men toggle-->
            <a class="uk-navbar-toggle uk-hidden@l" href="#side-menu" uk-toggle> <span uk-navbar-toggle-icon="ratio: 1.3"></span>
              <span class="uk-margin-small-left">МЕНЮ</span> </a>
            <!--nav-->
            <ul class="uk-navbar-nav uk-visible@l">
              <li><a href="//obmencity.ru/about">О ГБУ Горжилобмен</a></li>
              <li><a href="//obmencity.ru/general-info">Общая информация</a></li>
              <li><a href="//obmencity.ru/info">Полезная информация</a></li>
              <li><a href="//obmencity.ru/contacts">Контакты</a></li>
            </ul>
          </div>
          <!--search toggle-->
          
          <!--div class="nav-overlay uk-navbar-right">
            <a class="uk-navbar-item"> <span uk-icon="icon: key; ratio: 1.3"></span>
              <small class="uk-visible@xl">Личный кабинет</small>
            </a> <a href="#" class="js__accessibility-toggle uk-navbar-item" uk-tooltip="title: Версия для слабовидящих">
            <img class="go-header__accessibility-toggle" src="/public/obmencity/img/icons/main/gzho-icon__accessibility@2x.png" width="40" height="27"></a>
            <a class="uk-navbar-toggle" uk-search-icon="ratio: 1.3" uk-toggle="target: .nav-overlay; animation: uk-animation-slide-right" href="#"></a>
          </div-->
          
          <!--search field-->
          <!--div class="nav-overlay uk-navbar-left uk-flex-1" hidden>
            <div class="uk-navbar-item uk-width-expand">
              <form class="uk-search uk-search-navbar uk-width-1-1">
                <input class="uk-search-input" type="search" placeholder="Поиск по порталу" autofocus>
              </form>
            </div>
            <a class="uk-navbar-toggle uk-close-large" uk-close uk-toggle="target: .nav-overlay; animation: uk-animation-slide-right" href="#"></a>
          </div-->
        </nav>
      </div>
      <!--header-slider {#% include "@obmencity/header_slider.twig" %#} -->      
    </header>
</template>
<script setup>
</script>