import popups from "@/libs/popups"
import escapeXml from "@/libs/escapeXml"
import ComplexError from "@/api/complexError";

export default class Errors {
    constructor(debug = false) {
        this.initHandlers()
        this.debug = debug
        this.onInvalidToken = ()=>{}
    }

    initHandlers() {
        window.addEventListener('error',(errorMsg, url, lineNumber)=>{
            //Ошибки JS
            this.showError(errorMsg);
        });
        window.addEventListener('unhandledrejection',(promRejEv) => {
            //Ошибки в promise
            this.showError(promRejEv.reason);
        });        
    }

    processError(err) {
        console.error('Global Error:', err);
        //let message = 'Произошла внутренняя ошибка';
        let message = null;
        //Просто строка
        if (this.debug && typeof(err)==='string') {
            message = 'Error:<br>'+err;
        }
        
        //Объект с аппсервера
        if (typeof(err)==='object' && err.SymbCode) {
            message = 'Произошла внутренняя ошибка в серверной части';
            if (this.debug && typeof(err.Message)==='string') {
                message = 'Server Error:<br>'+err.Message;
            }
            if (this.debug && err.SymbCode === 'QUERY_ERROR') {
                message = 'Query Error '+err.query+':<br>'+escapeXml(JSON.stringify(err.queryParams))+'<br>'+escapeXml(err.Message);
            }
            if (err.SymbCode === 'INVALID_TOKEN') {
                message = null;
                this.onInvalidToken()
            }
            if (err.SymbCode === 'QUERY_USER_ERROR') {
                message = err.Message;
            }
        
        //ComplexError
        } else if (err instanceof ComplexError) {
            if (err.name === "InvalidToken") {
                message = null;
                this.onInvalidToken()
            } else if (this.debug) {
                message = err.name+": "+err.message                
            } else {
                message = err.message
            }
    
        //Объект JS эксепшн
        } else if (typeof(err)==='object' && typeof(err.message)==='string') {
            message = err.message
            if (message === "ResizeObserver loop limit exceeded") {
                message = null
            }
            if (!this.debug) {
                message = null
            }
        }
    
        return message;
    }
    
    showError(err) {
        console.error(err)
        let message = this.processError(err);
        if (!message) return;
        message = message.replace(/(?:\r\n|\r|\n)/g, '<br/>'); //nl2br
        popups.alert(message);
    }
}