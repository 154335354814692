<template>
<footer class="go-footer uk-background-muted accessibility-view-hide go-border-top">
  <div class="go-footer-copyright accessibility-view-hide">Горжилобмен © 1927-{{ nowYear }}</div>
</footer>
</template>
<script setup>
import { reactive, computed } from 'vue'

const nowYear = computed(() => {
  return new Date().getFullYear()
})
</script>
