<template>
  <Layout v-if="loaded">
    <router-view/>
  </Layout>
</template>
<script setup>
  import Layout from './layout'
  import { ref, onMounted } from 'vue'
  import { useStore } from 'vuex'
  import { useRoute, useRouter } from 'vue-router'
  import { errors } from './main'

  const store = useStore()
  const router = useRouter()
  const route = useRoute()
  const loaded = ref(false)
  
  onMounted(async () => {
    errors.onInvalidToken = ()=>{
      store.dispatch('session/logout').then().catch(e => {})
      store.commit('profile/set',null)
      router.push({name:'login'})
      loaded.value = true
    }
    loaded.value = true
    //console.log('$store',)
  })
</script>
