import { createRouter, createWebHashHistory } from 'vue-router'
import store from '@/store'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: { requiresAuth: true },
    component:  () => import(/* webpackChunkName: "HomeView" */ '../views/home.vue')
  },
  {
    path: '/login',
    name: 'login',
    component:  () => import(/* webpackChunkName: "LoginView" */ '../views/login.vue')
  },
  {
    path: '/registration',
    name: 'registration',
    component:  () => import(/* webpackChunkName: "RegistrationView" */ '../views/registration.vue')
  },
  {
    path: '/add-program/:programCode?',
    name: 'addProgram',
    props: true,
    meta: { requiresAuth: true },
    component:  () => import(/* webpackChunkName: "AddProgramView" */ '../views/add-program'),
  },  
  {
    path: '/recover',
    name: 'recover',
    component:  () => import(/* webpackChunkName: "RecoverView" */ '../views/recover.vue')
  },
  {
    path: '/recover2/:login/:code',
    name: 'recover2',
    component:  () => import(/* webpackChunkName: "Recover2View" */ '../views/recover2.vue'),
    props: true,
  },
  {
    path: '/profile',
    name: 'profile',
    component:  () => import(/* webpackChunkName: "ProfileView" */ '../views/profile')    
  },  
  {
    path: '/privatization',
    name: 'privatization',
    meta: { requiresAuth: true },
    component:  () => import(/* webpackChunkName: "PrivatizationView" */ '../views/privatization')    
  },
  {
    path: '/financed',
    name: 'financed',
    meta: { requiresAuth: true },
    component:  () => import(/* webpackChunkName: "FinancedView" */ '../views/financed')    
  },
  {
    path: '/sales',
    name: 'sales',
    meta: { requiresAuth: true },
    component:  () => import(/* webpackChunkName: "SalesView" */ '../views/sales')    
  },
  {
    path: '/program/:programId/:docId',
    name: 'program',
    props: true,
    meta: { requiresAuth: true },
    component:  () => import(/* webpackChunkName: "ProgramView" */ '../views/program')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  }
})
router.beforeEach(async (to, from) => {
  console.log('beforeEach',to, from)
  if (to.meta.requiresAuth) {
    let profileLoaded = !!store.state.profile.loaded
    let hasProfile = !!store.state.profile.data
    console.log("hasProfile",profileLoaded,hasProfile)
    if (profileLoaded && !hasProfile) {
      return '/login'
    }
  }
})

export default router
