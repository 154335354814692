import dayjs from 'dayjs'
import IQueries from './index'

export default class QueriesAppserv extends IQueries {
    
    constructor(appserver) {
        super()
        /** @type {CarabiClass} */
        this.appserver = appserver
    }

    async queryRaw (queryName, params = {}, count=1, offset=0, options={}) {
        let result = await this.appserver.queryRaw(queryName, params, count, offset, options)

        for (let i in result) {
          for (let paramI in result) {
            const p = result[paramI]
            if (p.type !== 'CURSOR') continue
            for (let coln in p.value.columns) {
              const colType = p.value.columns[coln][1]
              if (colType === "NUMBER") {
                //Парсим цифры
                for (let i in p.value.list) {
                  const row = p.value.list[i]
                  let num = parseFloat(row[coln])
                  if (isNaN(num)) num = null
                  result[paramI].value.list[i][coln] = num
                }
              } else if (colType === 'DATE' && !options.convertDate) {
                //Конвертация даты в ISO
                for (let i in p.value.list) {
                  const row = p.value.list[i]
                  let date = null
                  if (row[coln]) {
                    let djs = dayjs(row[coln], ["DD.MM.YYYY^HH:mm:ss", "DD.MM.YYYY HH:mm:ss", "DD.MM.YYYY"]).tz("Europe/Moscow", true)
                    //let djs = dayjs(row[coln], ["DD.MM.YYYY^HH:mm:ss", "DD.MM.YYYY HH:mm:ss", "DD.MM.YYYY"]).utc('z') //время, в UTC
                    date = djs.isValid()? djs.format(): null
                    //console.log("dateconv",row[coln],date,djs)
                  }
                  result[paramI].value.list[i][coln] = date
                }                
              }
            }
          }
        }

        return result
    }

}