import { createStore } from 'vuex'
import profile from './profile'
import session from './session'

export default createStore({
  modules: {
    session,
    profile
  }
})
