<template>
<div class="cabinet-panel uk-card uk-box-shadow-small uk-card-small go-border uk-border-rounded">
    <div class="body">
      <div class="menu">
        <button class="uk-button uk-button-primary" type="button">
          Выбрать программу
          <span uk-icon="icon: chevron-down"></span>
        </button>
        <div ref="dropdown" uk-dropdown="mode: click" style="max-width: 320px; max-height: 250px; overflow-y: auto;">
            <ul class="nav-programs uk-nav">
              <li v-for="prog in programs" :key="prog.name" :class="{'uk-active':prog.active}">
                <a @click.prevent="selectProg(prog)" href="#">{{ prog.short }}</a>
              </li>
              <!-- {% if pageName == curPage %}class="uk-active"{% endif %}><a href="/{{pageName}}/">{{page.short}}</a></li>-->
            </ul>
        </div>                  
      </div>
      <button @click="gotoProfile()" class="uk-button uk-button-default" style="line-height: 16px; margin-right: 5px">
        <div class="uk-text-bold">{{name1}}</div>
        <div>{{name2}}</div>
      </button>
      <div class="logout">
          <a @click.prevent="logout" class="uk-button uk-button-primary" ><span uk-icon="icon: sign-out"></span> выход</a>
      </div>
    </div>
  <!--div class="uk-card-body uk-background-muted uk-text-large uk-margin-remove">
    <{% if attached %}
      <button id="change-dog" class="uk-button uk-button-primary uk-float-right" type="button" uk-tooltip="title: Изменить договор; pos: left"><span uk-icon="icon: pencil"></span></button>
    {% endif %}>
    {{ title }}
  </div-->
</div>
</template>
<script setup>
  import programRedirect from '@/libs/program-redirect'
  import uikit from 'uikit'
  import { computed, ref } from 'vue'
  import { useRouter } from 'vue-router'
  import { useStore } from 'vuex'

  const dropdown = ref(null)
  const store = useStore()
  const router = useRouter()
  //const name = computed(() => store.getters['profile/fullName'])
  const name1 = computed(() => store.state.profile.data.LAST_NAME)
  const name2 = computed(() => {
    let p = store.state.profile.data
    return p.FIRST_NAME+" "+p.PATRONYMIC      
  })

  const programs = computed(() => {
    let result = store.state.profile.programs.map(p=>({
      "name": p.PROGRAM_CODE,
      "short": p.PROGRAM_NAME,
      "active": false,
      "program": p
    }))
    result.push({
      "name": "addProgram",
      "short": "Добавление программы"
    })
    return result
  })

  async function logout() {
    await store.dispatch('session/logout')
    store.commit('profile/set',null)
    router.push({name:'login'})
  }

  function selectProg(prog) {
    uikit.dropdown(dropdown.value).hide(false)
    if (prog.name === 'addProgram') {
      router.push({name:'addProgram'})
    } else {
      programRedirect(router, prog.program)
    }
  }

  function gotoProfile() {
    router.push({name:'profile'})    
  }
</script>
<style lang="sass">
@import "~@/assets/uikit.sass"

.nav-programs
  font-size: 1.1rem
  a
    color: #5d8fc2
    line-height: 2rem

.cabinet-panel
  .body
    padding: 13px 20px
    display: grid
    grid-template-columns: 1fr auto auto    
    .menu
      grid-column: 1 / 1
      grid-row: 1 / 1
      @media (max-width: $breakpoint-small)
        margin-top: 10px
        grid-column: 1 / span 2
        grid-row: 2 / 2
        text-align: center
    .name
      grid-column: 2 / 2
      grid-row: 1 / 1
      padding: 0 10px
      text-align: right
      @media (max-width: $breakpoint-small)
        grid-column: 1 / 1
        grid-row: 1 / 1
        text-align: left
        padding: 0
    .logout
      grid-column: 3 / 3
      grid-row: 1 / 1
      @media (max-width: $breakpoint-small)
        grid-column: 2 / 2
        grid-row: 1 / 1
</style>
