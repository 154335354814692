<template>
<div id="side-menu" class="uk-modal-full" uk-modal>
  <div class="uk-modal-dialog">
    <button class="uk-modal-close-full uk-close-large" type="button" uk-close></button>
    <div class="uk-height-viewport uk-flex uk-flex-center uk-flex-middle">
      <div>
        <p class="uk-h1 uk-margin-small-bottom">Навигация</p>
        <hr class="uk-divider-small">
        <ul class="uk-nav-primary font-secondary uk-nav-default" uk-nav>
          <li><a href="//obmencity.ru/about">О ГБУ Горжилобмен</a></li>
          <li><a href="//obmencity.ru/general-info">Общая информация</a></li>
          <li><a href="//obmencity.ru/info">Полезная информация</a></li>
          <li><a href="//obmencity.ru/contacts">Контакты</a></li>
        </ul>
      </div>
    </div>
  </div>
</div>
</template>