import UIkit from 'uikit'
import UIkitIcons from 'uikit/dist/js/uikit-icons'
UIkit.use(UIkitIcons);

import dayjs from './libs/dayjs'

import { Datepicker } from 'vanillajs-datepicker'
//import ru from "vanillajs-datepicker/js//i18n/locales/ru.js"
//Object.assign(Datepicker.locales, ru)
Datepicker.locales.ru = {
    days: ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"],
    daysShort: ["Вск", "Пнд", "Втр", "Срд", "Чтв", "Птн", "Суб"],
    daysMin: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
    months: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
    monthsShort: ["Янв", "Фев", "Мар", "Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"],
    today: "Сегодня",
    clear: "Очистить",
    format: "dd.mm.yyyy",
    weekStart: 1,
    monthsTitle: 'Месяцы'
}

import Errors from './libs/errors'
import {loadConfig} from '@/libs/config'
import ApiClass from './api'

import { createApp } from 'vue'
import App from './App.vue'
//import './registerServiceWorker'
import deleteServiceWorker from './deleteServiceWorker'
import router from './router'
import store from './store'

/** @type {Errors} */
export let errors = null
export let config = null
/** @type {ApiClass} */
export let api = null

async function main() {
    deleteServiceWorker().catch(e => console.error("deleteServiceWorker",e))
    errors = new Errors()
    config = await loadConfig()
    console.log('config',config)
    api = new ApiClass(config)
    //console.log('session',store.state.session.values)

    try {
      store.commit('session/load')
      if (!store.state.session.values) {
        throw new Error("Not authorised")
      }
      await store.dispatch('profile/reload')
    } catch(e) {
      console.log("Session Error",e)
      store.dispatch('session/logout').then().catch(e => {})
      store.commit('profile/set',null)
    }

    const app = createApp(App).use(store).use(router)
    app.config.errorHandler = (err, vm, info) => errors.showError(err)
    app.mount('#app')
}

main().then()
